<script lang="ts" setup></script>

<template>
    <footer class="bg-abc-footer text-gray-300 print:hidden">
        <div class="container py-10 text-xs leading-5 lg:flex lg:leading-6">
            <div class="flex-auto">
                <p>
                    American Book Company<br />
                    103 Executive Drive<br />
                    Woodstock GA 30188-1383
                </p>
                <NuxtLink to="mailto:contact@americanbookcompany.com">
                    <NuxtImg src="/images/footer/email.svg" class="mr-2 inline" />
                    contact@americanbookcompany.com
                </NuxtLink>
                <br />
                <NuxtLink to="tel:18882645877">
                    <NuxtImg src="/images/footer/phone.svg" class="mr-2 inline" />
                    1-888-264-5877
                </NuxtLink>
            </div>
            <div class="mt-6 flex-auto lg:mt-0">
                <h2 class="mb-1 text-sm font-semibold lg:mb-2">GET TO KNOW ABC</h2>
                <ul>
                    <li>
                        <NuxtLink to="/about-abc">About ABC</NuxtLink>
                        <br />
                    </li>
                    <li>
                        <NuxtLink to="/careers">Careers</NuxtLink>
                        <br />
                    </li>
                    <li>
                        <NuxtLink to="/articles">Blog</NuxtLink>
                        <br />
                    </li>
                </ul>
            </div>
            <div class="mt-6 flex-auto lg:mt-0">
                <h2 class="mb-1 text-sm font-semibold lg:mb-2">ORDERING</h2>
                <ul>
                    <li>
                        <NuxtLink to="/how-to-order">How to Order</NuxtLink>
                        <br />
                    </li>
                    <li>
                        <NuxtLink to="/documents-for-ordering">Documents for Ordering</NuxtLink>
                        <br />
                    </li>
                    <li>
                        <NuxtLink to="/catalog">Catalog</NuxtLink>
                        <br />
                    </li>
                </ul>
            </div>
            <div class="mt-6 flex-auto lg:mt-0">
                <h2 class="mb-1 text-sm font-semibold lg:mb-2">ACCOUNT LINKS</h2>
                <ul>
                    <li>
                        <NuxtLink to="/account">My Account</NuxtLink>
                        <br />
                    </li>
                    <li>
                        <NuxtLink to="/account/my-wishlist">My Wishlist</NuxtLink>
                        <br />
                    </li>
                    <li><a href="https://coursewave.com" target="_blank">CourseWave</a><br /></li>
                </ul>
            </div>
            <div class="mt-6 flex-none lg:mt-0">
                <NuxtImg src="/images/footer/bbb.png" class="h-14" alt="ABC Logo" />
            </div>
        </div>

        <div class="g:flex container border-t border-gray-500 py-3 text-xs leading-4 lg:leading-6">
            <div class="mb-4 flex-none pr-16 lg:mb-0">
                &copy; {{ new Date().getFullYear() }} American Book Company. All rights reserved.
            </div>
            <div class="mb-4 flex-none lg:mb-0">
                <NuxtLink to="/terms-of-use">Terms of Use</NuxtLink>
                <span class="mx-3">|</span>
                <NuxtLink to="/privacy-policy">Privacy Policy</NuxtLink>
                <span class="mx-3">|</span>
                <NuxtLink to="/accessibility">Accessibility</NuxtLink>
            </div>
            <div class="flex-1 lg:text-right">
                <span class="mr-4">Follow Us:</span>
                <a href="https://www.facebook.com/americanbookco" target="_blank" class="mr-4">
                    <NuxtImg src="/images/footer/facebook.svg" class="inline w-7" />
                </a>
                <a href="https://twitter.com/americanbookco" target="_blank" class="mr-4">
                    <NuxtImg src="/images/footer/twitter.svg" class="inline w-7" />
                </a>
                <a href="https://www.instagram.com/americanbookco/" target="_blank">
                    <NuxtImg src="/images/footer/instagram.svg" class="inline w-7" />
                </a>
            </div>
        </div>
    </footer>
</template>

<style scoped></style>
