<script lang="ts" setup>
// Properties
import { debounce } from "perfect-debounce";
import { storeToRefs } from "pinia";
import { onMounted, ref } from "vue";
import { useSearchStore } from "~/store/search";

// Properties
const props = defineProps({
    curState: String,
});

const route = useRoute();
const router = useRouter();

const userState = ref(props.curState);
const { searchText } = storeToRefs(useSearchStore());
const goToProductSearch = debounce(() => {
    if (!searchText.value) {
        return;
    }

    // If not on product search page, redirect to product search page
    if (
        !router.currentRoute.value.fullPath.includes("/products/state/") &&
        !router.currentRoute.value.fullPath.includes("/products/page/")
    ) {
        if (userState.value) {
            navigateTo(`/products/state/${userState.value.toLowerCase()}?search=${searchText.value}`);
            return;
        }

        navigateTo(`/products/state/ga?search=${searchText.value}`);
        return;
    }
}, 1000);

watch(searchText, (newVal, oldVal) => {
    goToProductSearch();
});

onMounted(async () => {
    searchText.value = route.query.search ? String(route.query.search) : searchText.value;
});
</script>

<template>
    <div
        class="relative mx-4 mb-3 flex flex-1 rounded-2xl border border-gray-300 px-4 hover:border-gray-400 md:mb-0 md:mr-0 md:w-3/5"
    >
        <input
            v-model="searchText"
            type="text"
            placeholder="Search..."
            class="flex-1 py-1 pl-2 text-base focus:outline-none"
        />
        <span v-show="!searchText" class="flex-none pt-1.5">
            <NuxtImg src="/images/header/mag_glass.svg" class="w-6" />
        </span>
        <span v-show="searchText" class="absolute right-5 cursor-pointer pt-2" @click="searchText = ''">
            <NuxtImg src="/images/fa/x-regular-duotone.svg" class="w-3" />
        </span>
    </div>
</template>

<style scoped></style>
